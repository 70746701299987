/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    blockquote: "blockquote",
    strong: "strong",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Ya he hablado ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/2009/09/22/introduccion-al-hardware-libre/",
    title: "Introducción al Hardware Libre"
  }, "antes"), " del Hardware Libre, pero siempre de pasada o sin dar mi opinión. Creo que ya va a siendo hora de ponerme enserio con este tema y empezar con uno de los objetivos de este blog, reunir y promocionar el Hardware Libre."), "\n", React.createElement(_components.p, null, "¿Que es Hardware Libre?"), "\n", React.createElement(_components.p, null, "La definición en Wikipedia es esta:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Se llama ", React.createElement(_components.strong, null, "hardware libre"), " a los dispositivos de ", React.createElement(_components.a, {
    href: "http://es.wikipedia.org/wiki/Hardware",
    title: "Hardware"
  }, "hardware"), " cuyas especificaciones y diagramas esquemáticos son de acceso público, ya sea bajo algún tipo de pago o de forma gratuita. La filosofía del ", React.createElement(_components.a, {
    href: "http://es.wikipedia.org/wiki/Software_libre",
    title: "Software libre"
  }, "software libre"), " (las ideas sobre la libertad del conocimiento) es aplicable a la del hardware libre. Se debe recordar en todo momento que libre ", React.createElement(_components.em, null, "no"), " es sinónimo de gratis. El hardware libre forma parte de la ", React.createElement(_components.a, {
    href: "http://es.wikipedia.org/wiki/Cultura_libre",
    title: "Cultura libre"
  }, "cultura libre"), "."), "\n"), "\n", React.createElement(_components.p, null, "Por lo tanto estamos hablando de dispositivos hardware, es decir que en un ordenador serían la parte física y tangible, pero también pueden ser otros aparatos electronicos, como por ejemplo un movil o dejando a parte cosas tan complicadas una simple ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/2008/02/01/antena-wi-fi/",
    title: "Antena wifi"
  }, "antena wifi"), ".  Es importante ver las licencias con que podemos proteger nuestro hardware para que no hagan mal uso de el, de esto hablare otro dia. Y también es importante saber en que formato vamos a liberar los diseños y las libertades que nos ofrecen. Este es otro punto problematico sobre el que también hablaré. Es importante que la gente a quien nos guste esta idea nos unamos para conseguir popularizar el HL."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
